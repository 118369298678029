import React from "react"
import PropTypes from "prop-types"

import "./layout.css"
import "./layout-desktop.css"
import Helmet from "react-helmet"

const Layout = ({ children }) => {

  return (
    <>
      <Helmet>
        <meta httpEquiv="refresh" content="0;url=https://facebook.us14.list-manage.com/subscribe?u=021ab6ec09678421151246d15&id=407853d480" />
      </Helmet>
        <main>{children}</main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
